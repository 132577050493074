import React from "react"
import { useField } from "react-final-form"
import styled from "styled-components"

import Spacer from "./Spacer"
import check from "./../assets/icons/check.svg"

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  input {
    transition: background-color linear 50ms;
    height: 20px;
    width: 20px;
    margin: 0;
    appearance: none;
    background-color: #ffffff;
    border: 1px solid #e9edf1;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ error }) => {
      if (error) return "red"

      return "#CED4DB"
    }};
    border-radius: 2px;
    margin-right: 8px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:checked {
      background-color: #d42b69;
      border-color: #d42b69;
      background-image: url(${check});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 85%;
    }
  }
  label {
    cursor: pointer;
    color: #1c1c26;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
  }
`

export default function Checkbox({ label, required, name, ...rest }) {
  const { input, meta } = useField(name, {
    validate: (value) => {
      if (!value && required) return "Required"
    },
    type: "checkbox",
  })

  let error

  if (meta.error && meta.touched) {
    error = !!meta.error
  } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
    error = !!meta.submitError
  }

  return (
    <>
      <StyledCheckbox {...rest} error={error}>
        <input id={name} {...input} />
        <label htmlFor={name}>{label}</label>
      </StyledCheckbox>
      <Spacer size={24} />
    </>
  )
}
