import React from "react"
import { Form } from "react-final-form"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { useToasts } from "react-toast-notifications"
import { useLoadScript, GoogleMap, OverlayView } from "@react-google-maps/api"

import Seo from "../components/Seo"
import PageHeader from "../components/PageHeader"
import Divider from "../components/Divider"
import Spacer from "../components/Spacer"
import Input from "../components/Input"
import Button from "../components/Button"
import Checkbox from "../components/Checkbox"
import MapIcon from "../assets/icons/map.svg"

const StyledContactDetails = styled.div`
  h2 {
    color: #1c1c26;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
  }

  p {
    color: #66768e;
    font-family: Roboto;
    font-size: 20px;
    line-height: 32px;
    a {
      color: #d42b69;
      &:hover {
        color: #d42b69;
      }
    }
  }

  .google-link {
    color: #f03a85;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    &:hover {
      color: #f03a85;
    }
  }
  .google-map {
    height: 300px;
    width: 100vw;
    margin: 0px -15px 0px -15px;
    @media screen and (min-width: 576px) {
      margin: 0px;
      width: auto;
    }

    @media screen and (min-width: 768px) {
      height: 100%;
    }
    .map-icon {
      height: 1px;
      width: 1px;
      position: relative;
      display: block;
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 48px;
        width: 44px;
        background-image: url(${MapIcon});
        background-size: contain;
        background-repeat: no-repeat;
        bottom: 0;
        left: -14px;
      }
    }
  }
`

export default function ContactUs() {
  const { addToast } = useToasts()

  const {
    wpPage: {
      seo,
      featuredImage,
      pageHeaderFields: { headerContent },
    },
    wp: {
      options: {
        optionsPageFields: {
          contactDetails: { address, email, phoneNumber },
        },
      },
    },
  } = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Contact" }) {
        pageHeaderFields {
          headerContent
        }
        seo {
          metaDesc
          title
          opengraphImage {
            localFile {
              publicURL
            }
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
      wp {
        options {
          optionsPageFields {
            contactDetails {
              address
              email
              phoneNumber
            }
          }
        }
      }
    }
  `)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDc2PCPW9qBH-tX6Sh0vOPrq00SZdnC0M8",
  })

  let googleMapComponent

  if (loadError) {
    googleMapComponent = <h5>Failed To Load Map</h5>
  } else if (!isLoaded) {
    googleMapComponent = <h5>Loading...</h5>
  } else {
    const center = {
      lat: 53.38205,
      lng: -1.4217115,
    }
    googleMapComponent = (
      <GoogleMap
        mapContainerClassName="google-map"
        center={center}
        zoom={15}
        clickableIcons={false}
        options={{
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#dadada",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "transit.line",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "transit.station",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#c9c9c9",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
          ],
          disableDefaultUI: true,
        }}
      >
        <OverlayView
          position={center}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <span className="map-icon" />
        </OverlayView>
      </GoogleMap>
    )
  }
  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <PageHeader headerContent={headerContent} />
      <Spacer size={32} when={{ lg: 48 }} />
      <Form
        onSubmit={async (values) => {
          // dont send mail if the honeypot has been filled in
          if (values.sweet) return

          await fetch("https://ddash.uk/mailer/send-mail", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${
                typeof window === "undefined"
                  ? ""
                  : window.btoa(`Daffodil:563919d14ce0adceffa46a6b61b23fa2`)
              }`,
            },
            body: JSON.stringify({
              host: "api.eu.mailgun.net",
              from:
                "Daffodil Marketing Website <website@daffodil-marketing.com>",
              to: "hello@daffodil-marketing.com",
              subject: `Daffodil Marketing contact form submission from ${values.name}`,
              text: `${values.name} ${
                values.company ? `from ${values.company}` : ""
              }has sent a message using the contact form on the website. They included the following message: ${
                values.message
              } To get in touch with ${values.name}, please call ${
                values.phone
              }, email ${values.email}, or reply to this email.`,
              replyTo: values.email,
              domain: "daffodil-marketing.com",
              html: `<p>${values.name} ${
                values.company ? `from ${values.company}` : ""
              }has sent a message using the contact form on the website.</p> <p> They included the following message:</p><p> ${
                values.message
              }</p> <p>To get in touch with ${values.name}, please call ${
                values.phone
              }, email ${values.email}, or reply to this email.</p>`,
              autoResponse: {
                from: "Daffodil Marketing <website@daffodil-marketing.com>",
                to: values.email,
                replyTo: "hello@daffodil-marketing.com",
                subject: "Thank you for getting in touch!",
                text: `Thank you for getting in touch with Daffodil Marketing! Someone will reply shortly. Kind Regards, Daffodil Marketing`,
                html: `<p>Thank you for applying for the role of ${values.position} at Daffodil Marketing! Someone will reply shortly.</p> <p>Kind Regards, </br>Daffodil Marketing</p>`,
              },
            }),
          }).then(({ status }) => {
            if (status === 200) {
              addToast("Thank you for getting in touch!", {
                appearance: "success",
              })
            } else {
              addToast("Something went wrong, please try again.", {
                appearance: "error",
              })
            }
          })
        }}
        render={({ handleSubmit, pristine, invalid }) => (
          <form onSubmit={handleSubmit} noValidate className="container">
            <div className="row">
              <div className="col-12">
                <h2>Get In Touch</h2>
                <Spacer size={32} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  name="name"
                  id="name"
                  type="text"
                  required
                  label="Name"
                  fullwidth
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  name="company"
                  id="company"
                  type="text"
                  label="Company"
                  fullwidth
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  name="email"
                  id="email"
                  type="email"
                  required
                  label="Email"
                  fullwidth
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  name="phone"
                  id="phone"
                  type="number"
                  required
                  label="Phone"
                  fullwidth
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Input
                  name="message"
                  id="message"
                  type="textarea"
                  required
                  label="Message"
                  fullwidth
                />
                <Input
                  name="sweet"
                  id="sweet"
                  type="text"
                  label="Sweet"
                  fullwidth
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Checkbox
                  label="I agree to Daffodil Marketing's Privacy Policy and Terms & Conditions"
                  required
                  name="terms"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Button
                  type="submit"
                  disabled={pristine || invalid}
                  colour="primary"
                  variant="contained"
                >
                  Send Message
                </Button>
              </div>
            </div>
          </form>
        )}
      />
      <Spacer size={32} when={{ lg: 48 }} />
      <Divider />
      <Spacer size={32} when={{ lg: 48 }} />
      <StyledContactDetails className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <h2>Get in Touch</h2>
            <Spacer size={16} />
            <p>
              Call us:
              <a href={`tel: ${phoneNumber}`}>{` ${phoneNumber}`}</a>
              <br />
              Email us:
              <a href={`mailto: ${email}`}>{` ${email}`}</a>
            </p>
            <Spacer size={32} when={{ md: 40 }} />
            <h2>Where we are</h2>
            <Spacer size={16} />
            <div
              className="address"
              dangerouslySetInnerHTML={{
                __html: `<p>${address}<p>`,
              }}
            />
            <Spacer size={32} when={{ md: 40 }} />
            <a
              className="google-link"
              target="__blank"
              href="https://goo.gl/maps/FrhEVrRPt1AGmxs77"
            >
              Open in Google Maps
            </a>
            <Spacer size={32} when={{ md: 4 }} />
          </div>
          <div className="col-12 col-md-6">{googleMapComponent}</div>
        </div>
      </StyledContactDetails>
      <Spacer size={32} when={{ md: 48, lg: 80 }} />
    </>
  )
}
